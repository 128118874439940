body {
  font-family: 'Raleway', sans-serif;
}

.jumbotron {
  color: white;
}

h6 {
  font-style: italic;
}

h2 {
  font-weight: 400;
  font-family: 'Farro', sans-serif;
}

header h2 {
  color: #cade87;
}

.home-btn {
  font-family: 'Farro', sans-serif;
  font-size: smaller;
}

.navbar {
  font-family: 'Farro', sans-serif;
  background-color: white;
}

.no-underline {
  text-decoration: none !important;
}

footer {
  font-family: 'Raleway', sans-serif;
  background-image: linear-gradient(white, gray);
  color: white;
}

.menu {
  font-family: 'Farro', serif;
}

.menu .menu-text {
  font-family: 'Raleway', sans-serif;
}

.card-header {
  background-color: #cade87;
  color: white;
}

.card {
  background: rgba(0, 0, 0, 0);
}

#map {
  height: 400px;
  /* The height is 400 pixels */
  width: 100%;
  /* The width is the width of the web page */
}

.zoom {
  transition: transform 1s; /* Animation */
}

.zoom:hover {
  transform: scale(
    1.05
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.box-shadow {
  box-shadow: 0 0 10px gray;
}

.btn-tan {
  background-color: #deb887;
}

.bg-beige {
  background-color: #faf5ee;
}

.product {
  display: none;
}

.green-bg {
  background-color: #cade87;
  color: #333333;
}

.btn-secondary {
  background-color: #5a5454;
}

.dark-gradient {
  background: #5a5454;
  background: -moz-linear-gradient(to bottom, #5a5454 0%, #333333 100%);
  background: -webkit-linear-gradient(to bottom, #5a5454 0%, #333333 100%);
  background: linear-gradient(to bottom, #5a5454 0%, #333333 100%);
}

.link-unstyled,
.link-unstyled:hover {
  color: inherit;
  text-decoration: inherit;
}

.page-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.page-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: 300ms ease-out;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: 300ms ease-in;
}
